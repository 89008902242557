<template>
  <div>
    <ParallaxEffect />
    <div class="filler-box"></div>
    <ContentBanner />
    <div class="filler-box"></div>
  </div>
</template>

<script>
import ParallaxEffect from './components/ParallaxEffect'
import ContentBanner from './components/ContentBanner'
import 'tailwindcss/tailwind.css'

export default {
  name: 'App',
  components: {
    ParallaxEffect,
    ContentBanner
  }
}
</script>

<style lang="scss">
  /* Import Animate.css styles */
  @import "~animate.css/animate.min.css";
  @import "~tailwindcss/tailwind.css";

  * {
    margin: 0;
  }

  html {
    background: #3a4c34;
  }

  .filler-box {
    background: #3a4c34;
    height: 200px;
    width: 100%;
  }

  p {
    color: #fff;
    bottom: 0;
    background-color: #3a4c34;
    text-align: right;
    padding: 10px 30px;
  }
</style>
