<template>
    <PatternBorder class="z-1 animate__animated animate__slideInRight" style="z-index:2; position: relative;" />
    <div class="z-0 filler-box h-400 flex items-center justify-center content-box animate__animated animate__slideInLeft">
      <div class="text animate__animated animate__zoomIn animate__delay-1s animate__slower">Lorem ipsum dolor</div>
    </div>
    <PatternBorder class="animate__animated animate__slideInRight" style="transform:rotate(180deg);" />
</template>

<script>
import PatternBorder from './PatternBorder'
import '../../node_modules/tailwindcss/tailwind.css'

export default {
  name: 'ContentBanner',
  components: {
    PatternBorder
  }
}
</script>

<style scoped lang="scss">
  @import "~tailwindcss/tailwind.css";

    .content-box {
      background: #185c72;
      margin: -20px 0;
      height: 320px;
      display: flex;
      justify-content: center;
      align-items: center;

      .text {
        color: #fff;
        text-transform: uppercase;
        font-size: 5rem;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
      }
    }
</style>
