<template>
    <img src="https://zelda.nintendo.com/breath-of-the-wild/assets/img/patterns/divider-blue.svg" alt="Patterns">
</template>

<script>
export default {
  name: 'ParallaxEffect'
}
</script>

<style scoped lang="scss">
    img {
        width: 100%;
        filter: brightness(0) saturate(100%) invert(100%) sepia(17%) saturate(0%) hue-rotate(191deg) brightness(115%) contrast(100%);
    }
</style>
